<div #actionBar>
  @if (layoutConfig && layoutConfig.subtitle) {
    <div class="header--actionbar">
      <div>
        <div class="actionbar--menu">
          <div class="flex-left">
            @if (layoutConfig.subtitle | observablePipe | async; as subTitle) {
              <h1 data-test="actionbar-header">{{ subTitle }}</h1>
            } @else {
              <h1>
                <cap-skeleton height="40px" width="340px"></cap-skeleton>
              </h1>
            }
          </div>
          <div class="flex-right flex-wrap">
            @for (button of layoutConfig?.actionButtons; track button) {
              @if (!(button?.isHidden | observablePipe | async | boolify)) {
                <ng-container *ngxPermissionsOnly="button.permissions">
                  <cap-button
                    appPreventDoubleClick
                    [debounceTime]="button.debounceTime | debounceTimeByType: button.type"
                    [attr.data-test]="
                      button.testAttrName
                        ? button.testAttrName
                        : layoutConfig.entity
                          ? (layoutConfig.entity | e2eAttr: button.type)
                          : null
                    "
                    [label]="
                      (button.label
                        ? (button.label | observablePipe | async | translate)
                        : layoutConfig.entity
                          ? ('dxp.' + layoutConfig.entity + '.button.' + button.type | translate)
                          : null
                      ) | default: ''
                    "
                    [icon]="button.icon | observablePipe | async"
                    [disabled]="(button.icon | observablePipe | async) === 'fas fa-spinner fa-pulse'"
                    [styleClass]="button.styleClass | default: 'primary'"
                    (clickEvent)="layoutConfigService.doAction(button)">
                  </cap-button>
                </ng-container>
              }
            }
            @if (layoutConfig?.actionMenu) {
              <app-action-menu [actionItems]="layoutConfig.actionMenu" class="mb-0"></app-action-menu>
            }
          </div>
        </div>
      </div>
    </div>
  }
</div>
